import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "textfield"
    }}>{`TextField`}</h1>
    <ContentBlock imgName="Form-textField.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    struct TextField<Label> where Label : View
*/
struct FormTextField: View {
    @State private var input: String = ""\n
    var body: some View {
        Form {
            TextField("Default/plain text field", text: $input)\n
            TextField("Text field with rounded borders", text: $input)
                .textFieldStyle(RoundedBorderTextFieldStyle())
        }
    }
}
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "textfieldstyle"
    }}><inlineCode parentName="h2">{`.textFieldStyle()`}</inlineCode></h2>
    <p>{`Can be modified to have rounded borders with `}<inlineCode parentName="p">{`.textFieldStyle(RoundedBorderTextFieldStyle())`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`TextField("Text field with rounded borders", text: $input)
    .textFieldStyle(RoundedBorderTextFieldStyle())
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      